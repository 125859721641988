$std-space: 8px;
$nodeMargin: 6 * $std-space; // enough room for "(vii)"
$icon-padding: $std-space;

$scroll-bar: 14px;

$sm-breakpoint: 768px;

.mt-1 {
    margin-top: $std-space * 1 !important; }

.mt-2 {
    margin-top: $std-space * 2 !important; }

.mt-3 {
    margin-top: $std-space * 3 !important; }

.mr-1 {
    margin-right: $std-space * 1 !important; }

.mr-2 {
    margin-right: $std-space * 2 !important; }

.mr-3 {
    margin-right: $std-space * 3 !important; }

.mb-1 {
    margin-bottom: $std-space * 1 !important; }

.mb-2 {
    margin-bottom: $std-space * 2 !important; }

.mb-3 {
    margin-bottom: $std-space * 3 !important; }

.ml-1 {
    margin-left: $std-space * 1 !important; }

.ml-2 {
    margin-left: $std-space * 2 !important; }

.ml-3 {
    margin-left: $std-space * 3 !important; }
