// https://codepen.io/sdthornton/pen/wBZdXq
@mixin shadow() {
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24); }
@mixin ddshadow() {
    box-shadow: 1px 1px 28px rgba(0, 0, 0, 0.1); }

@mixin rounded() {
    border-radius: 6px; }

@mixin rounded8() {
    border-radius: 6px; }

.rounded {
    @include rounded(); }
.rounded-8 {
    @include rounded8(); }

.boxShadow {
    @include shadow(); }

.ddBoxShadow {
    @include ddshadow(); }

@mixin textPop() {
    text-shadow: 0 1px 0 rgba(0,0,0,0.5); }

@mixin grad() {
    background-image: linear-gradient(rgba(255,255,255,.08), rgba(255,255,255,.04) 40%, rgba(0,0,0,.04) 60%, rgba(0,0,0,.08)); }
