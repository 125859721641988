
$blue: #1976d2;
$lt-blue: #EDF5FA;
$exlt-blue: #FAFCFE;
$dk-blue: #004ba0;

$orange: #f57c00;
$lt-orange: #FEEEE6;
$dk-orange: #d66e03;

$sideNav-lt-orange: #FFE7CC;
$sideNav-dk-orange: #F66322;
$sideNav-dk-grey: #6C6D73;
$sharePopper-dk-grey: #606060;

$green: #388e3c;
$dk-green: #00600f;
$lt-green: #6abf69;

$almost-white: #f5f7f9;
$off-white: #eeeeee;

$v-lt-grey: #D3D3D3;
$lt-grey: #8e8e8e;
$grey: #516F90;
$s-dk-grey: #494949;
$dk-grey: #373737;
$bdr-grey: #CDCDCF;

$body-bg: #FBFDFF;
$white: #ffffff;
$outline: #E2EBFA;

$editor-bg: #F8F9FD;

$red: #d32f2f;
$pink: #c2185b;
$purple: #7b1fa2;
$deep-purple: #512da8;
$indigo: #303f9f;
$light-blue: #0288d1;
$cyan: #0097a7;
$teal: #00796b;
$light-green: #689f38;
$lime: #afb42b;
$yellow: #fbc02d;
// ...
$brown: #5d4037;

$text: #33475B /* times.com */;

$warning: #b00020; // https://material.io/design/color/the-color-system.html#color-theme-creation

@mixin colorize($color) {
    border-left: 4px solid $color;
    color: $color; }

@mixin btn-color($color) {
    background-color: $color;
    border-color: adjust-hue($color, -5); }

@mixin btn-outline-primary($color) {
    background-color: transparent;
    border-color: $color;
    color: $orange; }

.btn-primary {
    @include btn-color($orange); }

.btn-outline-primary {
    @include btn-outline-primary($orange);
    color: $orange; }

.btn-outline-primary:hover {
    @include btn-color($orange);
    color: $white; }

.btn-primary:hover, .btn-primary:active {
    @include btn-color($dk-orange); }

.text-primary {
    color: $orange !important; }
