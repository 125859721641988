@import "colors";
@import "util";
@import "spacing";

.dropdownPopper .popper {
    padding: 0;
    z-index: 9999;
    left: -8px !important; }

.dropdownCustomMenu, .dropdown-menu {
    padding: 0;
    margin: 0;
    @include ddshadow(); }

.dropdownCustomMenu.inline-menus {
    display: flex;

    > div:last-child {
        > div {
            border-left: 1px solid $outline; } }

    > div:first-child {
        >div:nth-child(2) {
            .dropdownIcon {
                display: none; } } } }

.dropdown-item .dropdownName {
    margin-right: 0;
    margin-left: 8px; }

.dropdown-item {
    display: flex;
    color: $text;
    padding: 8px 20px;
    cursor: pointer;

    > img {
        margin-right: 8px; }


    .dropdownIcon {
        color: $grey;
        max-width: 20px;
        display: flex;
        align-items: center;
        .material-icons-outlined {
            font-size: 20px; } }

    .dropdownName {
        min-width: 20px;
        flex-grow: 1;
        margin-right: $std-space; }
    .dropdownArrow {
        display: flex;
        span {
            color: $grey; } } }
#popper-AH1P0Yv6UA {
    .dropdownIcon {
        margin-right: 8px; } }
.dropdownBar {
    margin: $std-space 0;
    border-bottom: thin solid $outline; }

.dropdown-item:last-child {
    border-bottom: none; }

.dropdown-item:hover, .dropdown-item:focus {
    background-color: $lt-blue; }

.selectMenu {
    margin-top: 10px;

    .menuItemExpander {
        display: inline-block;
        width: 26px; } }

.insertOptionPopper {
    .popperBody {
        overflow: auto;
        max-height: 300px; } }
